import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect () {
    console.log('action-bar connect')
    this.check();
    window.addEventListener("resize", this.check.bind(this));
  }

  check() {
    console.log(document.querySelector("body").clientHeight, window.innerHeight)
    if (document.querySelector("body").clientHeight > window.innerHeight) {
      this.element.classList.add("blur")
    } else {
      this.element.classList.remove("blur")
    }
  }

}
