import { Application } from "@hotwired/stimulus"

import loading from "./loading_controller"
import toggle from "./toggle_controller"
import new_connection from "./new_connection_controller"
import question from "./question_controller"
import consent from "./consent_controller"
import timeline from "./timeline_controller"
import action_bar from "./action_bar_controller"

window.Stimulus = Application.start()

// Stimulus.handleError = (error, message, detail) => {
//   console.warn(message, detail)
//   // ErrorTrackingSystem.captureException(error)
// }

Stimulus.register("loading", loading)
Stimulus.register("toggle", toggle)
Stimulus.register("new-connection", new_connection)
Stimulus.register("question", question)
Stimulus.register("consent", consent)
Stimulus.register("timeline", timeline)
Stimulus.register("action-bar", action_bar)