import "@hotwired/turbo-rails"
import '@/javascript/controllers/index.js'; // Stimulus Controlelrs

import * as Sentry from '@sentry/browser';

if (!(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
  Sentry.init({
    dsn: "https://9dd3fc323fdb4c78b57246999c179c40@sentry.io/1426952"
  });
}

import elementClosest from "element-closest";
elementClosest(window);