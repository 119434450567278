import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "errors" ]

  connect () {
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      checkbox.addEventListener("change", this.checkErrors.bind(this))
    })
  }

  checkErrors(event) {
    if (this.element.querySelectorAll('input[type="checkbox"]:checked').length === this.element.querySelectorAll('input[type="checkbox"]').length) {
      this.errorsTarget.classList.remove("active")
    }
  }

  validate(event) {
    if (this.element.querySelectorAll('input[type="checkbox"]:checked').length === this.element.querySelectorAll('input[type="checkbox"]').length) {
      this.errorsTarget.classList.remove("active")
    } else {
      event.preventDefault()
      event.stopImmediatePropagation();
      this.errorsTarget.classList.add("active")
      this.errorsTarget.scrollIntoView({ behavior: 'smooth' })
    }
  }
}