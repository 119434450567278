import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "options" ]
  static values = {
    active: { type: String, default: "" },
    open: { type: Boolean, default: false},
    id: {type: Number, default: 0}
  }

  connect() {
    console.log('timeline connect')
  } 

  initialize() {
    this.openValue = false
    this.setStyle = this.setStyle.bind(this);
  }

  select (event) {
    console.log('select', event)
    // this.activeValue = event.params.licenseType;
    // fetch(`/cart/assets/${this.idValue}/update_license/${this.activeValue}`, { method: 'POST' })
    //   .then(response => response.text())
    //   .then(html => Turbo.renderStreamMessage(html))
    //   .then(() => {
    //     this.openValue = false;
    //     this.setStyle();
    //   })
  }

  toggle (event) {
    console.log('toggle')
    event.stopPropagation();
    this.openValue = !this.openValue
    this.setStyle()
  }

  setStyle () {
    this.optionsTargets.forEach(t => {

      if (this.openValue) {
        t.style.overflow = 'hidden';
        t.style.transitionProperty = 'height';
        t.style.height = `${t.scrollHeight}px`;
        t.style.transitionDuration = '300ms';
        setTimeout(() => {
          t.style.height = `auto`;
        }, 300)
      } else {
        t.style.height = `${t.scrollHeight}px`;
        setTimeout(() => {
          t.style.overflow = 'hidden';
          t.style.transitionProperty = 'height';
          t.style.height = `0px`;
          t.style.transitionDuration = '300ms';  
        }, 100)
      }
    })
  }

}
